var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { FocusTrap } from '../focus-trap';
const isNavBarMMItem = (item) => item.type === 'NavItemWithMM';
const isNavBarItemLink = (item) => item.type === 'NavItemLink';
export class MegaMenuNav {
    constructor(primaryNavElement, adapter) {
        this.activeButton = null;
        this.activeMenu = null;
        this.customEvents = {};
        this.navItems = [];
        this.loader = document.createElement('div');
        this.loaderContainer = document.createElement('div');
        this.offsetMenuClickListener = (event) => this.handleOffsetMenuClick(event);
        this.offsetMenuKeyPressListener = (event) => this.handleOffsetKeypress(event);
        this.element = primaryNavElement;
        this.adapter = adapter;
        this.focusTrap = new FocusTrap(this.element);
        this.content = document.createElement('template');
        this.loader.classList.add('nci-is-loading', 'hidden');
        this.loaderContainer.classList.add('nci-megamenu', 'hidden');
        this.loaderContainer.ariaLive = 'polite';
        this.loaderContainer.ariaBusy = 'true';
        this.loaderContainer.ariaAtomic = 'true';
        this.loaderContainer.appendChild(this.loader);
        this.initialize();
    }
    unregister() {
        this.navItems.forEach((item) => {
            this.unregisterMenuItem(item);
        });
        this.removeEventListeners();
        this.loader.remove();
        this.loaderContainer.remove();
    }
    unregisterMenuItem(item) {
        if (isNavBarMMItem(item)) {
            item.button.removeEventListener('click', item.buttonListener);
            item.button.replaceWith(item.link);
        }
        else if (isNavBarItemLink(item)) {
            item.link.removeEventListener('click', item.linkListener);
        }
    }
    initialize() {
        const listItems = this.element.querySelectorAll('.nci-header-nav__primary-link');
        this.navItems = Array.from(listItems).map((item) => {
            const button = this.createNavButton(item);
            if (button === null) {
                const linkListener = this.addLinkEventListeners(item);
                return {
                    type: 'NavItemLink',
                    link: item,
                    linkListener,
                };
            }
            const buttonListener = this.addButtonEventListeners(button);
            return {
                type: 'NavItemWithMM',
                link: item,
                button,
                buttonListener,
            };
        });
        this.createCustomEvents();
        this.addOffsetMenuListeners();
    }
    createNavButton(link) {
        const id = link.dataset.menuId;
        if (id == null) {
            return null;
        }
        const button = document.createElement('button');
        button.innerHTML = link.innerHTML;
        button.classList.add('usa-button', 'nci-header-nav__primary-button');
        button.setAttribute('aria-expanded', 'false');
        if (link.classList.contains('usa-current')) {
            button.classList.add('usa-current');
        }
        button.setAttribute('data-menu-id', id);
        button.setAttribute('aria-controls', `menu-${id}`);
        link.replaceWith(button);
        return button;
    }
    addButtonEventListeners(button) {
        const listener = (event) => __awaiter(this, void 0, void 0, function* () { return this.handleButtonClick(event); });
        button.addEventListener('click', listener);
        return listener;
    }
    handleButtonClick(event) {
        return __awaiter(this, void 0, void 0, function* () {
            const button = event.target;
            yield this.toggleMegaMenu(button);
        });
    }
    addLinkEventListeners(link) {
        const listener = (event) => __awaiter(this, void 0, void 0, function* () { return this.handleLinkClick(event); });
        link.addEventListener('click', listener);
        return listener;
    }
    handleLinkClick(event) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const link = event.currentTarget;
            const label = ((_a = link.textContent) !== null && _a !== void 0 ? _a : '').trim();
            this.element.dispatchEvent(this.customEvents['linkclick']({
                label,
                href: link.href,
                link,
            }));
        });
    }
    handleOffsetMenuClick(event) {
        if (this.activeButton && this.activeMenu) {
            const withinBoundaries = event.composedPath().includes(this.element);
            if (!withinBoundaries) {
                this.collapseMegaMenu();
            }
        }
    }
    handleOffsetKeypress(event) {
        if (this.activeButton && this.activeMenu) {
            const isEscapePressed = event.key === 'Escape';
            if (isEscapePressed) {
                this.collapseMegaMenu();
            }
        }
    }
    toggleMegaMenu(button) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.activeButton === button) {
                this.collapseMegaMenu();
            }
            else {
                if (this.activeButton) {
                    this.collapseMegaMenu();
                }
                yield this.expandMegaMenu(button);
            }
        });
    }
    collapseMegaMenu() {
        if (this.activeButton && this.activeMenu) {
            const collapseDetails = this.getDetailsForExpandCollapse(this.activeButton);
            this.focusTrap.toggleTrap(false, this.activeButton);
            this.activeButton.setAttribute('aria-expanded', 'false');
            this.activeButton = null;
            this.activeMenu.classList.add('hidden');
            this.activeMenu.setAttribute('aria-hidden', 'true');
            this.activeMenu.remove();
            this.activeMenu = null;
            this.element.dispatchEvent(this.customEvents['collapse'](collapseDetails));
        }
    }
    expandMegaMenu(button) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.createMenu(button);
            this.focusTrap.toggleTrap(true, button);
            this.activeButton = button;
            this.activeButton.setAttribute('aria-expanded', 'true');
            const menuId = this.activeButton.getAttribute('aria-controls');
            this.activeMenu = this.element.querySelector(`#${menuId}`);
            if (this.activeMenu) {
                this.activeMenu.classList.remove('hidden');
                this.activeMenu.setAttribute('aria-hidden', 'false');
                this.activeMenu.hidden = false;
            }
            this.element.dispatchEvent(this.customEvents['expand'](this.getDetailsForExpandCollapse(this.activeButton)));
        });
    }
    getDetailsForExpandCollapse(button) {
        var _a, _b;
        const btnText = ((_a = button.textContent) !== null && _a !== void 0 ? _a : '').trim();
        const id = (_b = button.dataset.menuId) !== null && _b !== void 0 ? _b : '';
        return {
            label: btnText,
            id,
            button,
        };
    }
    createMenu(button) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            button.after(this.loaderContainer);
            const timer = setTimeout(() => {
                this.loader.classList.remove('hidden');
                this.loaderContainer.classList.remove('hidden');
            }, 1000);
            const menuId = (_a = button.dataset.menuId) !== null && _a !== void 0 ? _a : '';
            const results = yield this.adapter.getMegaMenuContent(menuId);
            if (results) {
                clearTimeout(timer);
            }
            const id = `menu-${menuId.toString().replace(/[^\w\s]/gi, '')}`;
            this.content = results || document.createElement('div');
            this.content.setAttribute('id', id);
            this.content.classList.add('hidden');
            this.content.ariaLive = 'polite';
            this.content.ariaBusy = 'false';
            this.content.ariaAtomic = 'true';
            this.loader.classList.add('hidden');
            this.loaderContainer.classList.add('hidden');
            this.loaderContainer.replaceWith(this.content);
            button.setAttribute('aria-controls', id);
        });
    }
    addOffsetMenuListeners() {
        document.addEventListener('click', this.offsetMenuClickListener, false);
        document.addEventListener('keydown', this.offsetMenuKeyPressListener, false);
    }
    removeEventListeners() {
        document.removeEventListener('click', this.handleOffsetMenuClick, false);
        document.removeEventListener('keydown', this.handleOffsetKeypress, false);
    }
    createCustomEvents() {
        const events = ['collapse', 'expand'];
        [...events].forEach((event) => {
            this.customEvents[event] = (detail) => new CustomEvent(`nci-header:mega-menu:${event}`, {
                bubbles: true,
                detail,
            });
        });
        this.customEvents['linkclick'] = (detail) => new CustomEvent(`nci-header:primary-nav:linkclick`, {
            bubbles: true,
            detail,
        });
    }
}
