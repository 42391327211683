import { SiteAlertCloseButton } from './utils/site-alert-close-button.component';
import { SiteAlertCollapse } from './utils/site-alert-collapse.component';
export class USASiteAlert {
    constructor(element, options) {
        this.element = element;
        this.options = Object.assign(Object.assign({}, USASiteAlert.optionDefaults), options);
        const existingComponent = USASiteAlert._components.get(this.element);
        if (existingComponent) {
            existingComponent.unregister();
        }
        USASiteAlert._components.set(this.element, this);
        this.initialize();
    }
    static create(element, options) {
        if (!(element instanceof HTMLElement)) {
            throw 'Element is not an HTMLElement';
        }
        return this._components.get(element) || new this(element, options);
    }
    static createAll() {
        const siteAlerts = Array.from(document.getElementsByClassName('usa-site-alert'));
        return siteAlerts.map((siteAlert) => {
            const closable = siteAlert.hasAttribute('data-site-alert-closable');
            return this.create(siteAlert, {
                closeable: closable,
            });
        });
    }
    static autoInit() {
        document.addEventListener('DOMContentLoaded', () => {
            this.createAll();
        });
    }
    unregister() {
        if (this.collapse) {
            this.collapse.unregister();
        }
        if (this.closeButton) {
            this.closeButton.unregister();
        }
        USASiteAlert._components.delete(this.element);
    }
    initialize() {
        this.createId();
        this.createCloseButton();
        this.createCollapse();
    }
    createId() {
        const siteAlerts = Array.from(document.querySelectorAll('.usa-site-alert'));
        this.element.id = `site-alert-${siteAlerts.indexOf(this.element)}`;
    }
    createCloseButton() {
        if (this.options.closeable && this.element.id) {
            this.closeButton = new SiteAlertCloseButton(this.element, this.options);
        }
    }
    createCollapse() {
        if (this.element.querySelector('.usa-alert__nci-header') &&
            this.element.querySelector('.usa-alert__nci-content') &&
            this.element.id) {
            this.collapse = new SiteAlertCollapse(this.element, this.options);
        }
    }
}
USASiteAlert.optionDefaults = {
    closeable: false,
    closeAriaLabel: 'Dismiss alert',
    closeButtonClass: 'usa-alert__nci-button--close',
    closeEventListenerLabel: 'usa-site-alert:close-button',
    closeCookiePath: '/',
    collapseAriaLabel: 'Toggle alert message',
    collapseButtonClass: 'usa-alert__nci-button--toggle',
    collapseCookiePath: '/',
    collapseEventListenerLabel: 'usa-site-alert:content',
};
USASiteAlert._components = new Map();
