import { FooterCollapse } from '../utils/footer-collapse';
import { NCIBackToTop } from '../utils/footer-back-to-top';
import { NCISubscribe } from '../../nci-subscribe/nci-subscribe.component';
export class NCIBigFooter {
    constructor(element, options) {
        this.collapses = [];
        this.collapseWidth = 480;
        this.resizeEventListener = (e) => this.handleResize(e);
        this.handleScrollEventListener = () => this.handleScroll();
        this.element = element;
        this.options = Object.assign(Object.assign({}, NCIBigFooter.optionDefaults), options);
        this.collapseMediaQuery = matchMedia(`(min-width: ${this.collapseWidth}px)`);
        const existingComponent = NCIBigFooter._components.get(this.element);
        if (existingComponent) {
            existingComponent.unregister();
        }
        NCIBigFooter._components.set(this.element, this);
        this.initialize();
    }
    static create(element, options) {
        if (!(element instanceof HTMLElement)) {
            throw 'Element is not an HTMLElement';
        }
        return this._components.get(element) || new this(element, options);
    }
    static autoInit() {
        document.addEventListener('DOMContentLoaded', () => {
            const footers = Array.from(document.getElementsByClassName('usa-footer'));
            footers.forEach((footer) => {
                this.create(footer);
            });
        });
    }
    unregister() {
        if (this.form) {
            this.form.unregister();
            this.form = undefined;
        }
        if (this.backToTop) {
            this.backToTop.unregister();
            this.backToTop = undefined;
        }
        this.unregisterCollapses();
        this.removeEventListeners();
        NCIBigFooter._components.delete(this.element);
    }
    unregisterCollapses() {
        this.collapses.forEach((collapse) => {
            collapse.unregister();
        });
        this.collapses = [];
    }
    initialize() {
        this.createSubscribe();
        this.addEventListeners();
        this.removeLegacyBackToTop();
        const currentWidth = window.innerWidth;
        if (currentWidth < this.collapseWidth) {
            this.createCollapsibleSections();
        }
    }
    handleResize(query) {
        if (query.matches) {
            this.unregisterCollapses();
        }
        else {
            this.createCollapsibleSections();
        }
    }
    createCollapsibleSections() {
        const collapses = this.queryCollapsibleSections();
        collapses.forEach((collapse, index) => {
            this.collapses[index] = new FooterCollapse(collapse, {
                collapseButtonClass: 'usa-footer__primary-link',
                collapseClass: 'usa-footer__primary-content--collapsible',
                collapseEventListenerLabel: 'usa-footer:nav-links',
                collapseWidth: this.collapseWidth,
            });
        });
    }
    queryCollapsibleSections() {
        const selector = '.usa-footer__primary-content--collapsible';
        return Array.from(this.element.querySelectorAll(selector));
    }
    createSubscribe() {
        const form = this.element.querySelector('form');
        if (form) {
            this.form = NCISubscribe.create(form, {
                subscribeInvalidEmailAlert: this.options.subscribeInvalidEmailAlert,
                subscribeEventListenerLabel: 'usa-footer:sign-up',
            });
        }
    }
    removeLegacyBackToTop() {
        const linkElement = this.element.getElementsByClassName('usa-footer__return-to-top')[0];
        if (linkElement)
            linkElement.remove();
    }
    handleScroll() {
        if (this.options.backToTopText === null)
            return;
        if (window.scrollY > 0) {
            if (!this.backToTop) {
                this.backToTop = new NCIBackToTop(this.element, this.options.backToTopText);
            }
            window.removeEventListener('scroll', this.handleScrollEventListener);
        }
    }
    addEventListeners() {
        this.collapseMediaQuery.addEventListener('change', this.resizeEventListener);
        if (this.options.backToTopText !== null) {
            window.addEventListener('scroll', this.handleScrollEventListener);
        }
    }
    removeEventListeners() {
        this.collapseMediaQuery.removeEventListener('change', this.resizeEventListener);
        window.removeEventListener('scroll', this.handleScrollEventListener);
    }
}
NCIBigFooter.optionDefaults = {
    subscribeInvalidEmailAlert: 'Enter a valid email address',
    backToTopText: 'Back to Top',
};
NCIBigFooter._components = new Map();
