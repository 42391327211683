import { DefaultMegaMenuSource } from '../mega-menu/default-mega-menu-source';
import { DefaultMobileMenuSource } from '../mobile-menu/default-mobile-menu-source';
import { MegaMenuNav } from '../utils/mega-menu/mega-menu-nav';
import { MobileMenu } from '../utils/mobile-menu/mobile-menu';
import { Search } from '../utils/search';
export class NCIExtendedHeaderWithMegaMenu {
    constructor(element, options) {
        this.searchInputFocusHandler = (event) => this.handleSearchFocus(event);
        this.searchInputBlurHandler = (event) => this.handleSearchBlur(event);
        this.element = element;
        this.options = options;
        this.megaMenuNav = this.wireUpMegaMenu();
        this.mobileMenu = this.wireUpMobileMenu();
        const searchFormEl = this.element.querySelector('form.nci-header-search');
        if (searchFormEl) {
            this.searchForm = new Search(searchFormEl, this.searchInputFocusHandler, this.searchInputBlurHandler);
        }
        const valid = Search.isSearchFormValid();
        if (valid) {
            this.searchDiv = this.element.querySelector('.nci-header-nav__secondary');
        }
        const existingComponent = NCIExtendedHeaderWithMegaMenu._components.get(this.element);
        if (existingComponent) {
            existingComponent.unregister();
        }
        NCIExtendedHeaderWithMegaMenu._components.set(this.element, this);
    }
    static create(element, options) {
        if (!(element instanceof HTMLElement)) {
            throw 'Element is not an HTMLElement';
        }
        return this._components.get(element) || new this(element, options);
    }
    static autoInit() {
        document.addEventListener('DOMContentLoaded', () => {
            const headers = Array.from(document.getElementsByClassName('nci-header'));
            headers.forEach((header) => {
                this.create(header, {
                    megaMenuSource: new DefaultMegaMenuSource(),
                    mobileMenuSource: new DefaultMobileMenuSource(),
                });
            });
        });
    }
    unregister() {
        if (this.searchForm) {
            this.searchForm.unregister();
        }
        this.megaMenuNav.unregister();
        this.mobileMenu.unregister();
        NCIExtendedHeaderWithMegaMenu._components.delete(this.element);
    }
    wireUpMegaMenu() {
        const navigation = this.element.querySelector('.nci-header-nav__primary');
        return new MegaMenuNav(navigation, this.options.megaMenuSource);
    }
    wireUpMobileMenu() {
        const navigation = this.element;
        return new MobileMenu(navigation, this.options.mobileMenuSource);
    }
    handleSearchFocus(event) {
        event.preventDefault();
        this.searchDiv.classList.add('search-focused');
    }
    handleSearchBlur(event) {
        event.preventDefault();
        setTimeout(() => {
            this.searchDiv.classList.remove('search-focused');
        }, 250);
    }
}
NCIExtendedHeaderWithMegaMenu._components = new Map();
