export class NCISubscribe {
    constructor(form, options) {
        this.customEvents = {};
        this.submitEventListener = (e) => this.handleSubmit(e);
        this.form = form;
        this.options = options;
        this.invalidEmailAlert = this.createInvalidEmailAlert();
        const existingComponent = NCISubscribe._components.get(this.form);
        if (existingComponent) {
            existingComponent.unregister();
        }
        NCISubscribe._components.set(this.form, this);
        this.initialize();
    }
    static create(element, options) {
        if (!(element instanceof HTMLElement)) {
            throw 'Element is not an HTMLElement';
        }
        return this._components.get(element) || new this(element, options);
    }
    unregister() {
        this.removeEventListeners();
        this.toggleFormErrors(true);
        this.invalidEmailAlert.remove();
        NCISubscribe._components.delete(this.form);
    }
    initialize() {
        this.addEventListeners();
        this.createCustomEvents();
        this.addMessage();
    }
    addEventListeners() {
        this.form.addEventListener('submit', this.submitEventListener);
    }
    removeEventListeners() {
        this.form.removeEventListener('submit', this.submitEventListener);
    }
    handleSubmit(event) {
        event.preventDefault();
        const form = this.form;
        const elements = form.elements;
        const input = elements.namedItem('email');
        const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(input.value);
        if (isValid) {
            this.toggleFormErrors(true);
            this.form.dispatchEvent(this.customEvents['submit']);
            form.submit();
        }
        else {
            this.toggleFormErrors(false);
            this.form.dispatchEvent(this.customEvents['error']);
            input.focus();
        }
    }
    createInvalidEmailAlert() {
        const alert = document.createElement('span');
        const alertID = 'email-error-message';
        alert.classList.add('usa-error-message', 'hidden');
        alert.id = alertID;
        alert.innerHTML = this.options.subscribeInvalidEmailAlert;
        alert.setAttribute('aria-hidden', String(true));
        alert.setAttribute('role', 'alert');
        return alert;
    }
    addMessage() {
        const input = this.form.querySelector('.usa-input');
        input.setAttribute('aria-describedby', this.invalidEmailAlert.id);
        input.before(this.invalidEmailAlert);
    }
    toggleFormErrors(isValid) {
        const group = this.form.querySelector('.usa-form-group');
        isValid
            ? group.classList.remove('usa-form-group--error')
            : group.classList.add('usa-form-group--error');
        const label = this.form.querySelector('.usa-label');
        isValid
            ? label.classList.remove('usa-label--error')
            : label.classList.add('usa-label--error');
        const input = this.form.querySelector('.usa-input');
        isValid
            ? input.classList.remove('usa-input--error')
            : input.classList.add('usa-input--error');
        const alert = this.form.querySelector('.usa-error-message');
        isValid ? alert.classList.add('hidden') : alert.classList.remove('hidden');
        alert.setAttribute('aria-hidden', String(isValid));
    }
    createCustomEvents() {
        const events = ['submit', 'error'];
        [...events].forEach((event) => {
            this.customEvents[event] = new CustomEvent(`${this.options.subscribeEventListenerLabel}:${event}`, {
                detail: this.form,
            });
        });
    }
}
NCISubscribe._components = new Map();
