export class SiteAlertCloseButton {
    constructor(element, options) {
        this.customEvents = {};
        this.eventListener = () => this.handleClose();
        this.element = element;
        this.options = options;
        this.button = this.createButton();
        this.initialize();
    }
    unregister() {
        this.removeEventListeners();
        this.element.style.display = '';
        this.button.remove();
    }
    initialize() {
        this.hideSiteAlert();
        this.createCustomEvents();
        this.addButton();
        this.addEventListeners();
    }
    createButton() {
        const button = document.createElement('button');
        button.classList.add('usa-alert__nci-button', this.options.closeButtonClass);
        button.setAttribute('aria-label', this.options.closeAriaLabel);
        button.innerHTML +=
            '<svg class="usa-icon" role="img" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M0 13.0332964L13.0332964 0M13.0332964 13.0332964L0 0" transform="translate(1 1)"/></svg>';
        return button;
    }
    hideSiteAlert() {
        var _a;
        const cookie = (_a = document.cookie
            .match(`(^|;)\\s*USASiteAlert${this.element.id}\\s*=\\s*([^;]+)`)) === null || _a === void 0 ? void 0 : _a.pop();
        if (cookie === 'hidden') {
            this.element.style.display = 'none';
        }
    }
    addButton() {
        const alert = this.element.querySelector('.usa-alert');
        alert.append(this.button);
    }
    addEventListeners() {
        this.button.addEventListener('click', this.eventListener);
    }
    removeEventListeners() {
        this.button.removeEventListener('click', this.eventListener);
    }
    handleClose() {
        document.cookie = `USASiteAlert${this.element.id}=hidden; Path=${this.options.closeCookiePath}`;
        this.hideSiteAlert();
        this.element.dispatchEvent(this.customEvents['close']);
    }
    createCustomEvents() {
        this.customEvents['close'] = new CustomEvent(`${this.options.closeEventListenerLabel}:close`, {
            detail: this.element,
        });
    }
}
